<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card  class="projectDetail el-main">
			<div class="projectDetail-top a-flex-csbfs ">
				<div class="a-flex-rfsfs">
					<span class="a-fs-16 a-fw-700 a-ff-dm">{{ detail.name }}</span>
				</div>
				<div class="a-mt-16 a-flex-rsbc a-flex-wrap a-w-100">
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">商户全称</div>
						<span>{{ detail.name }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">注册时间</div>
						<span>{{ detail.createTimeText }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">所在地区</div>
						<span>{{ detail.province + detail.city + detail.district }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">商户类型</div>
						<span>{{ detail.newTypeText }}</span>
					</div>
                    <div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">加盟类型</div>
						<span>{{ detail.companyType | initDic(agencyTypeDic) }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">法人/负责人</div>
						<span>{{ detail.repName }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">营业执照</div>
						<div class="a-flex-rfsc">
						  <LeBtnPreviewImg :imgList="[detail.certImagePath]"
                                v-if="detail.certImagePath"></LeBtnPreviewImg>
						</div>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">创建时间</div>
						<span>{{ detail.createTimeText }}</span>
					</div>
					<div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">首次收益时间</div>
						<span>{{ detail.firstIncomeTime }}</span>
					</div>
                    <div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">客服电话</div>
						<span>{{ detail.phone || '-' }}</span>
                        <div class="a-flex-rcc" @click="updateCustomerPhone">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08 a-c-blue font-point">更改</span>
                        </div>
					</div>
                    <div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">独立钱包状态</div>
						<span>{{ detail.serviceProfit?'开启':'关闭' }}</span>
					</div>
					<div class="projectDetail-top-item">
                        <div class="wa pdr16 fontC17">客诉通知人</div>
                        <div class="a-flex-cfsfs">
                            <span v-for="(item,index) in complaintUserList" :key="index">{{ item.name }} {{ item.mobile }}</span>
                        </div>
                        <div class="a-flex-rcc" @click="addComplaintUserList">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08 a-c-blue font-point">更改</span>
                        </div>
                    </div>
                    <div class="projectDetail-top-item">
						<div class="wa pdr16 fontC17">基础收款商户</div>
						<span>{{ detail.baseCompany | initDic(baseCompanyDic) }}</span>
					</div>
				</div>
				<div class="franchisee-statistics">
					<div class="franchisee-box">
						<div class="franchisee-title">账户可用余额(元)</div>
						<div class="franchisee-num">{{ '￥' + config.numFormat((detail.balance || 0) + (detail.serviceProfitBalance || 0)) }}</div>
					</div>
					<div class="franchisee-box">
						<div class="franchisee-title-o">冻结金额(元)</div>
						<div class="franchisee-num-o">{{ '￥' + config.numFormat((detail.balanceLock || 0) + (detail.serviceProfitLockBalance || 0)) }}</div>
					</div>
					<div class="franchisee-box">
						<div class="franchisee-title-o">待入账(元)</div>
						<div class="franchisee-num-o">{{ '￥' + config.numFormat((detail.balanceWaitConfirm || 0) + (detail.serviceProfitWaitConfirmBalance || 0)) }}</div>
					</div>
				</div>
				<div class="a-mt-32 projectDetail-top-tabs a-w-100">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="收益统计" name="tjxx">
							<EarningsTrend :id="companyId" :params="earningsTrendParams" :infoType="'business'"></EarningsTrend>
						</el-tab-pane>
						<el-tab-pane label="加盟合同" name="fcbl">
							<div class="projectDetail-top-tabs-box fcbl  a-w-100">
								<div class="border4 pd24 bodr4 w400 posiR" v-if="agencyDetail">
									<div class="fontC4 font14 mgt12">甲方<span class="fontC2 mglr8">{{
											agencyDetail.parentCompanyName || ''
									}}</span></div>
									<div class="fontC4 font14 mgt12">乙方<span class="fontC2 mglr8">{{
											agencyDetail.companyName || ''
									}}</span></div>
									<div class="fontC4 font14 mgt12">乙方营收占比<span class="fontC2 mglr8">{{
											agencyDetail.rate/100 || 0
									}}%</span></div>
									<div class="fontC4 font14 mgt12">代理合同
										<span class="fontC2 mglr8 font-point">
											<span class="mgr10">{{ agencyDetail.contractFile || '' }}</span>
											<a @click="download(agencyDetail.contractFile)" class="fontC5 textNone">下载
												<i class="el-icon-download"></i>
											</a>
										</span>
									</div>
									<div class="flex_end  mgt12 font-point" @click="applyChange">
										<span class="mgr6 fontC5 font14">{{ agencyDetail.isChange ? '变更中' : '申请变更'
										}}</span><i class="el-icon-arrow-right font12"></i>
									</div>
									<div class="franchisee-lv pdtb2 pdlr10">
										{{ agencyDetail.agencyLevel }}级加盟商
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="自营站点" name="htxx" v-if="$_has(6)">
							<div class="bgC1 w100p  bodr12">
								<SiteView :companyId="companyId" :isSearchAgency="0"></SiteView>
							</div>
						</el-tab-pane>
						<el-tab-pane label="分成站点" name="fcxx" v-if="$_has(6)">
							<div class="bgC1 w100p  bodr12">
								<SiteView :companyId="companyId" :isSearchAgency="4"></SiteView>
							</div>
						</el-tab-pane>
						<el-tab-pane label="设备总览" name="dfxx" v-if="$_has(12)">
							<div>
								<DeviceView :names="activeName" :companyId="companyId"></DeviceView>
							</div>
						</el-tab-pane>
						<el-tab-pane label="员工列表" name="zdxx" v-if="$_has(36)">
							<div class="bgC1 w100p  bodr12">
								<StaffeView :companyId="companyId"></StaffeView>
							</div>
						</el-tab-pane>
                        <el-tab-pane label="银行账户" name="yhzh">
							<div class="bgC1 w100p  bodr12">
                                <bank-card :companyId="companyId"></bank-card>
							</div>
						</el-tab-pane>
						<!-- <el-tab-pane label="合同变更记录" name="htbgjl">
							<div class="bgC1 w100p pd24 bodr12" v-if="agencyDetail">
								<ContractView :names="activeName" :agencyId="agencyDetail.id"></ContractView>
							</div>
						</el-tab-pane> -->
					</el-tabs>

				</div>
			</div>
		</el-card>
        <compalint-user-set ref="complaintUserSet" @handlerSuccess='handleAddComplaint'></compalint-user-set>
	</div>
</template>
<script>
import config from '../../../src/utils/util'
import leDateChangeTimeVue from '../components/form/le-date-changeTime.vue'
import SiteView from "./child/siteView.vue";//站点列表
import DeviceView from "./child/deviceView.vue";//设备列表
import StaffeView from "./child/staffeView.vue";//员工列表
import ContractView from "./child/contractView.vue";//员工列表
import LeBtnPreviewImg from '../components/poppup/le-btn-preview-img.vue';
import EarningsTrend from '../businessAnalysis/info-child/earningsTrend.vue';
import bankCard from './child/bankCard.vue';
import compalintUserSet from './child/compalint-user-set.vue';
export default {
	components: { 
        SiteView, 
        DeviceView, 
        StaffeView, 
        ContractView, 
        'le-date-changeTime': leDateChangeTimeVue,
        LeBtnPreviewImg, 
        EarningsTrend, 
        bankCard,
        compalintUserSet
    },
	data() {
		return {
			config: config,//公共方法集
			companyId: '',
			detail: {},
			activeT: 1,
			activeName: 'tjxx',
			agencyDetail: null,
			agencyId: '',
            earningsTrendParams: '',
            agencyTypeDic: [],
            complaintUserList: [],
            baseCompanyDic: [{
                label: '上海玖电科技有限公司',
                value: 1
            },{
                label: '深圳市源助力科技有限公司',
                value: 2
            }],//基础收款商户
		}
	},
	created() {
		this.companyId = parseInt(this.$route.query.code)
        this.earningsTrendParams = {
            companyId: this.companyId
        }
		this.getDetail()
        this.getcComplaintReminder()
        this.$getDic('companyType','select').then(res=>{ this.agencyTypeDic = res; })
	},
	mounted() {
		// this.echartsInit()
	},
	methods: {
		//tab切换
		handleClick(tab) {
			if (tab.index == '1') {
				this.getAgencyDetail()
			}
		},
		//加盟商信息详情
		getDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getOtherCompanyInfoNoCache,
                showLoading: false,
				params: {
					companyId: parseInt(this.companyId)
				}
			}).then((res) => {
				if (res.result.code == 0) {
					this.detail = res.result.data
					this.getAgencyDetail()
                    this.getQueryCustomerPhone()
				}
			})
		},
        // 查询加盟商自定义客服电话
        getQueryCustomerPhone () {
            this.$Axios._post({
                url: this.$Config.apiUrl.queryCustomerPhone,
                params: { 
                    companyId: this.companyId,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.detail.phone = res.result.data[0].phone
                }
            })
        },
        // 添加加盟商自定义客服电话
        updateCustomerPhone () {
            this.$prompt('请输入客服电话', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\d+$/,
                inputValue: this.detail.phone,
                inputErrorMessage: '格式不正确'
            }).then(({ value }) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.addUpdateCustomerPhone,
                    params: { 
                        companyId: this.companyId,
                        phone: value
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.getDetail()
                        this.$message.success('操作成功')
                    }
                })
            }).catch(() => {
   
            });
            
        },
        addComplaintUserList (datas) {
            this.$refs['complaintUserSet'].companyId = this.companyId
            this.$refs['complaintUserSet'].dialogVisible = true
        },
        // 查询客诉通知人
        getcComplaintReminder () {
            this.$Axios._post({
                url: this.$Config.apiUrl.complaintReminder,
                params: {
                    companyId: this.companyId,//所属商户
                },
            })
            .then(res => {
                if(res.result.code == 0){
                    this.complaintUserList = res.result.data.userInfoList
                }
            })
            .catch((error) => {
                this.$message.error(error.result.message)
            });
        },
        handleAddComplaint () {
            this.getcComplaintReminder()
        },
		//加盟合同
		getAgencyDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getAgencyDetail,
				params: {
					agencyCompanyId: parseInt(this.companyId)
				}
			}).then((res) => {
				if (res.result.code == 0) {
					this.agencyDetail = res.result.data
				} else {
					this.$message.error(res.result.message)
				}
			})
		},
		download(url) {
            fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                const a = document.createElement('a')
                a.href = URL.createObjectURL(blob)
                //测试链接console.log(a.href)
                a.download = 'fileName.xlsx'  // 下载文件的名字
                document.body.appendChild(a)
                a.click()
            })
        },
		setMoney(val) {
			var num = Number(val);
			if (!num) {//等于0
				return num + '.00';
			} else {//不等于0
				// num = Math.round((num)*100)/10000;
				// num = num.toFixed(2);
				num += '';//转成字符串
				var reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
				// console.log(num.indexOf('.')>-1)
				return num.replace(reg, '$1,')//千分位格式化
			}
		},
		applyChange(){
			if(this.agencyDetail.isChange){
				this.$router.push({path:'/franchisee/franchisee-change',query:{code:this.agencyDetail.companyId}})
			}else{
				this.$router.push({path:'/franchisee/franchisee-change',query:{code:this.agencyDetail.companyId}})
			}
		}

	}
}
</script>
<style lang="scss" scoped>
.projectDetail {
	font-size: 14px;
	height: 100%;
	overflow-y: auto;

	>div {
		border-radius: 4px;
	}

	&-box {
		border-radius: 4px;
		background: #fff;
	}

	&-top {
		background: #fff;
		// padding: 24px;
		padding-bottom: 64px;
		position: relative;
		&-item {
			display: flex;
			flex-direction: row;
            align-items: flex-start;
			width: 33.3%;
			margin-top: 16px;
			font-size: 14px;
			line-height: 22px;

			>div {
				min-width: 116px;
			}
		}
		&-tabs {
			position: absolute;
			top: 300px;
			left: 0;
			.fcbl {
				// padding: 24px;
				background: #fff;
				border-radius: 4px;

				&-table {
					margin-top: 16px;
					width: 100%;
					border: 1px solid #DADDE0;
					padding: 0 16px 24px;

					>div {
						width: 100%;

						&:nth-of-type(1) {
							border: none;
							padding: 24px 0;
						}

						&:nth-of-type(2) {
							background: #F5F7FA;
						}

						padding:12px 0;
						border-bottom:1px solid #FAFBFC;
						display: flex;
						flex-flow: row;
						justify-content: space-between;
						align-items: center;

						>* {
							width: 50%;
							text-align: center;

						}
					}
				}
			}
			.htxx {
				background: #fff;
				border-radius: 4px;
				>div {
					width: 33.33%;
					margin-bottom: 16px;

					a {
						text-decoration: none;
						color: #007AFF;
						margin-left: 8px;
					}
				}
			}
		}
	}

	/deep/ .el-tabs__item {
		// color: #007aff;
		font-size: 14px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #007AFF;
		font-weight: bold;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background: none !important;
	}

	/deep/ .el-tabs__header {
		// margin-left: 24px;
	}
}

.franchisee {
	&-statistics {
		width: 100%;
		background: linear-gradient(90deg, #F5F7FA 0%, #FBFCFD 100%);
		border-radius: 4px;
		padding: 16px 24px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 16px;
	}

	&-box {
		width: 33%;
	}

	&-title {
		font-size: 14px;
		color: #303133;
		margin-bottom: 8px;
	}

	&-title-o {
		font-size: 14px;
		color: #606366;
		margin-bottom: 13px;
	}

	&-num {
		font-size: 24px;
		color: #007AFF;
		font-weight: 600;
	}

	&-num-o {
		font-size: 14px;
		color: #303133;
	}
}

.w90p {
	width: 90%;
}

.franchisee-lv {
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 4px;
	border: 1px solid #FFA900;
	font-size: 12px;
	font-weight: 400;
	color: #FFA900;
}
</style>